<template>
  <nav
    class="navbar navbar-wrapper navbar-expand-lg"
    ref="navbarWrapper"
    :style="[cssVars, styles]"
  >
    <div class="container">
      <div class="top">
        <a
          v-if="!!logo"
          class="navbar-brand p-0"
          :href="logo.url || '/'"
          aria-label="Website"
        >
          <img
            :src="require(`@/${logo.imageSource}`)"
            :alt="logo.alt"
          />
        </a>
        <button
          v-if="fields.length > 0"
          class="navbar-toggler"
          type="button"
          @click="checkBoxClicked"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div
        class="navbar-collapse expandedDiv"
        ref="expandedDiv"
      >
        <ul class="navbar-nav">
          <li
            class="navbarLinks"
            v-for="field in fields"
            :key="field.fieldId"
          >
            <componentRenderer
              :key="field.id"
              :field="field"
            />
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import componentRenderer from "@/components/component-renderer.vue";

export default {
  name: "Navbar",
  components: {
    componentRenderer
  },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {
          "background": "#2CA4A3",
          "color": "#FFF"
        }
      }
    },
    title: {
      type: String
    },
    logo: {
      type: Object,
      default: () => {}
    },
    fields: {
      required: false,
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    cssVars () {
      return {
        '--color': this.styles.color,
        '--bg-color': this.styles.background
      }
    }
  },
  methods: {
    checkBoxClicked () {
      const expandedDiv = this.$refs['expandedDiv'];
      const navbarWrapper = this.$refs['navbarWrapper']
      expandedDiv.style.maxHeight = expandedDiv.style.maxHeight ? null : expandedDiv.scrollHeight + 10 + "px";
      // navbarWrapper.style.borderRadius = navbarWrapper.style.borderRadius === '10px' ? '30px' : "10px"
      navbarWrapper.style.height = navbarWrapper.style.height === '150px' ? null : "150px"
    }
  }
};
</script>

<style lang="scss" scoped>
.navbar-wrapper {
  padding: 10px;
  justify-content: space-between;
  @media(max-width: 450px){
    max-width: 100vw !important;
    display: flex;
    align-items: center;
    margin: 0px !important;
  }
}
.navbar-brand {
  img {
    max-width: 100px;
    margin: 0 20px;
    @media(max-width: 450px){
      // max-width: 90px;
      margin: 0px !important;
    }
  }
}
.top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media(max-width: 450px){
    flex-direction: row-reverse;
  }
}
.navbar-collapse{
  flex-grow:initial;
}
.navbar-toggler-icon{
  background-image: url('@/assets/images/svg/navbar-toggler.svg') !important;
  width: 24px !important;
  height: 24px !important;
}
.expandedDiv {
  @media (max-width: 991px) {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
    -moz-transition: height 0.4s ease-out;; /* Firefox 4 */
    -webkit-transition: height 0.4s ease-out;;  /* Safari and Chrome */
    -o-transition: height 0.4s ease-out;;  /* Opera */
  }
  @media(max-width: 450px){
    background: transparent;
  }
}
</style>
