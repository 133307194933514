<template>
  <div
    class="gray_block"
  >
    <div
      class="mainContent"
    >
      <div
        class="inner_gray_block mb-3"
      >
        <div
          class="desc"
          v-html="$t(texts.descText)"
        ></div>
        <div
          class="subDesc"
          v-html="$t(texts.descSubText)"
        ></div>
      </div>
      <div class="row info-wrapper">
        <div class="col-lg-6 col-sm-6 px-0">
          <img
            class="img-fluid receipt-image"
            :src="receiptImage"
            alt="receipt example"
          >
        </div>
        <div class="col-lg-6 col-sm-6">
          <ul class="wraper_punkt mx-2">
            <li
              class="media"
              :key="step.label"
              v-for="step in steps"
            >
              <div class="item">
                {{ $t(step.label) }}
              </div>
              <div class="media-body item_text">
                {{ $t(step.text) }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div
        v-if="!hideExamples"
        class="wrapper_check_block"
      >
        <div class="checkMark">
          <img
            class="img-fluid"
            :src="exampleImg(0)"
            alt="Correct"
          >
        </div>
        <div class="checkMark">
          <img
            class="img-fluid"
            :src="exampleImg(1)"
            alt="Incorrect"
          >
        </div>
        <div class="checkMark">
          <img
            class="img-fluid"
            :src="exampleImg(2)"
            alt="Incorrect"
          >
        </div>
        <div class="checkMark">
          <img
            class="img-fluid"
            :src="exampleImg(3)"
            alt="Incorrect"
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ReceiptInformation",
  props: {
    texts: {
      required: false,
      type: Object,
      default: () => {}
    },
    steps: {
      required: false,
      type: [Array, Object],
      default: () => []
    },
    hideExamples: {
      type: Boolean,
      default: false
    },
    imageSrc: {
      type: String,
      default: ""
    },
    differentExamples: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    pullDown: false
  }),
  computed: {
    shouldDisplayFull () {
      return screen.width > 992 ? true : false
    },
    receiptImage () {
      if (this.imageSrc) return require(`@/${this.imageSrc}`)
      return require("@/assets/images/png/receipt.png")
    },
  },
  methods: {
    pull () {
      this.pullDown = !this.shouldDisplayFull ? !this.pullDown : false
    },
    exampleImg (index) {
      if (this.differentExamples) return require(`@/assets/images/png/check_${index+1}_2.png`)
      return require(`@/assets/images/png/check_${index+1}.png`)
    }
  }
};
</script>
<style lang="scss" scoped>
.gray_block {
  background-color: transparent;
  padding: 10px 30px;
  border-radius: 4px;
}

.info-wrapper {
  flex-wrap: nowrap;
}
.wraper_punkt {
  margin-bottom: 15px;
  li.media {
    width: 250px;
    margin-bottom: 15px;
    font-size: 16px;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    .item {
      width: 20px;
      height: 20px;
      display: inline-block;
      color: #FFF;
      border-radius: 100px;
      text-transform: uppercase;
      margin-right: 10px;
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
    }
    .item_text {
      width: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.16px;
      text-align: left;
      color: #FFF;
    }
  }
}
.wrapper_check_block {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  column-gap: 20px;
  width: 100%;

  .checkMark {
    position: relative;
    &:first-child {
      margin-left: 0px;
    }
  }
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.receipt-image {
  width: 100%;
}

.desc {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #FFF;
  text-align: left;
  margin-bottom: 10px;
}
.subDesc {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #FFF;
  text-align: left;
}

.mainContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

@media (max-width: 767px) {
  .gray_block {
    padding: 5px;
  }

  .info-wrapper {
    > div {
      max-width: 50%;
    }
  }

  .wraper_punkt {
    li.media {
      width: auto;
      margin-bottom: 15px;
      .item {
        width: 15px;
        height: 15px;
        font-size: 12px;
        line-height: 15px;
      }
      .item_text {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }

  .wrapper_check_block {
    padding-top: 20px;
    flex-wrap: wrap;
    .checkMark {
      img {
        max-width: 120px;
      }
    }
  }

  .receipt-image {
    max-width: 150px;
  }

  .desc {
    font-size: 16px;
    line-height: 22px;
  }
}

</style>
