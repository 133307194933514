<template>
  <div class="product-info gray_block">
    <div>
      <div class="inner_gray_block">
        <span class="desc-text" v-html="$t(texts.desc)"></span>
      </div>
      <div class="row align-items-center justify-content-center">
        <img class="img-fluid" :src="require(`@/${image}`)" alt="Example image" />
      </div>
      <div class="row col-12 col-sm-12 col-md-6">
        <p class="subDesc-text" v-html="$t(texts.subDesc)">
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "product-information",
  props: {
    texts: {
      type: Object,
      default: () => {},
    },
    steps: {
      required: false,
      type: [Array, Object],
      default: () => []
    },
    color: {
      type: String,
      default: "#808080"
    },
    image: {
      required: false,
      type: String,
      default: "receipt"
    }
  }
};
</script>
<style lang="scss" scoped>
.desc-text {
  text-align: left;
  font-size: 18px;
}

.gray_block {
  padding: 60px 0px 0px 60px;
  border-radius: 4px;
  @media (max-width: 991px) {
    padding: 40px 60px;
  }
  @media (max-width: 767px) {
    padding: 40px 0;
  }
  .inner_gray_block {
    // margin-bottom: 50px;
  }
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 300px;
}
</style>
