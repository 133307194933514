<template>
  <div class="wrapper">
    <img
      :src="require('@/assets/images/svg/logo.svg')"
      alt="Gillette Logo"
    >
    <p>
      So viele Einlösungen sind noch verfügbar:
    </p>
    <div v-html="computedParticipations"></div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Participation",
  props: {
    text: {
      type: String,
      default: "Empty paragraph"
    },
    totalAllowedParticipations: {
      type: String,
      default: "50.000"
    }
  },
  data () {
    return {
      totalParticipations: ""
    };
  },
  async mounted () {
    const resp = await this.getParticipationCount()
    this.totalParticipations = resp.remaining
  },
  methods: {
    ...mapActions(["getParticipationCount"]),
  },
  computed: {
    computedParticipations () {
      if (this.totalParticipations === "nan") return 0

      // Convert the number to a string to easily access each digit
      const numStr = this.totalParticipations.toString();

      // Use Array.map to wrap each digit in a span and then join them into a single string
      return numStr.split('').map(digit => `<span class="digit">${digit}</span>`).join('');
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  column-gap: 20px;
  background: #010B48;
  color: #FFF;

  img {
    width: 140px;
    height: 30px;
  }

  p {
    font-size: 26px;
    font-weight: 600;
    line-height: 30px;
    margin: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 20px;

    p {
      text-align: center;
    }
  }
}

:deep(.digit) {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  background: #FFF;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 24px;
  font-weight: bold;
  margin: 0 5px;
}

</style>
