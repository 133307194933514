<template>
  <div class="footerWrapper">
    <div class="container-fluid">
      <div class="container">
        <div class="row top">
          <div class="col-lg-3">
            <img src="@/assets/images/svg/logo.svg" alt="Pampers Logo">
          </div>
          <div class="col-lg-9 text">
            Unsere Supportabteilung steht Ihnen bis zum 25.06.2024 zur Verfügung: <br> <b><a href="mailto:kontakt@pampers-pants-gratis-testen.de">kontakt@pampers-pants-gratis-testen.de</a></b>
          </div>
        </div>
      </div>
      <div class="row bottom">
        <div class="container row-gap-4">
          <div class="col-lg-12 col-sm-12">
            <ul class="links">
              <li>
                <a
                  href="https://termsandconditions.pg.com/de-de/"
                  target="_blank"
                >
                  {{ $t('Geschäftsbedingungen') }}
                </a>
              </li>
              <li>
                <a
                  href="https://preferencecenter.pg.com/de-de/"
                  target="_blank"
                >
                  {{ $t('Meine Daten') }}
                </a>
              </li>
              <li>
                <a
                  href="https://privacypolicy.pg.com/de-de/"
                  target="_blank"
                >
                  {{ $t('Datenschutz') }}
                </a>
              </li>
              <li>
                <a href="https://www.pampers.de/kontakt" target="_blank">
                  {{ $t('Kontakt') }}
                </a>
              </li>
              <li>
                <a
                  href="https://de.pg.com/impressum/"
                  target="_blank"
                >
                  {{ $t('Impressum') }}
                </a>
              </li>
            </ul>
          </div>
          <div class="col-lg-12 d-flex justify-content-between align-items-center">
            <div class="col-lg-8 d-flex align-items-center column-gap-4 offset-3 pg-logo">
              <img src="@/assets/images/png/pg-logo.png" width="80" alt="P&G Logo">
              <p>
                ©2024 Procter & Gamble
                <br>
                <span>
                  Alle Rechte vorbehalten.
                  <br>
                  Der Gebrauch und Zugang zu den Informationen auf dieser
                  <br>
                  Seite unterliegt unseren <a href="https://termsandconditions.pg.com/de-de/" target="_blank" rel="noopener noreferrer">Geschäftsbedingungen</a>.
                </span>
              </p>
            </div>
            <div class="col-lg-2 powered">
              Powered by
              <img src="@/assets/images/svg/js-logo.svg" width="40" alt="Justsnap">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent"
};
</script>

<style lang="scss" scoped>
@import "@/mixins/style/_color";
$tablet : 991px;

.footerWrapper {
  background-color: $color-1;
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid #fff;
}

.top {
  color: #FFF;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
  img {
    max-width: 150px;
  }

  .text {
    font-size: 18px;
    color: #FFF;
  }
}

.bottom {
  background-color: #FFF;
  padding: 20px 0;

  .container {
    max-width: 80%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  p {
    margin: 0;
  }
}
.links {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;

  a {
    color: #262626 !important;
    text-decoration: none !important;
  }

  li {
    margin-right: 30px;
    margin-top: 10px;
  }
}

.powered {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 30px;
  margin-left: 30px;

  img {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 767px) {
  .footerWrapper {
    padding: 30px 0 0 0;
    border-top: none;
    .container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 20px;
    }
  }

  .top {
    img {
      max-width: 100px;
    }

    .text {
      font-size: 12px !important;
    }
  }
  .links {
    flex-wrap: wrap;
    text-align: center;

    padding-bottom: 20px;
    border-bottom: 1px solid #2C2C2C;
    li {
      display: inline;
      &:not(:last-child) {
        margin-right: 10px;
        padding-right: 10px;
        border-right: 1px solid #2C2C2C;
      }
    }
  }

  .bottom {
    padding-bottom: 40px;
    font-size: 14px !important;
    .pg-logo {
      margin-left: 0;

      img {
        width: 60px;
      }
    }
  }


  .powered {
    display: none;
  }
}

</style>
