<template>
  <ValidationObserver ref="form">
    <form
      class="row"
      :id="fieldId"
      :style="styles"
    >
      <template v-for="field in fields">
        <componentRenderer
          :class="`col-lg-${field.properties.grid.columns}`"
          :field="field"
          @component-updated="updated"
          :key="field.id"
        />
      </template>
      <!-- <button @click="submit">SUBMIT</button> -->
    </form>
  </ValidationObserver>
</template>
<script>
import componentRenderer from "@/components/component-renderer.vue";
import { mapGetters } from "vuex";

export default {
  name: "FormComponent",
  components: { componentRenderer },
  props: {
    fieldId: String,
    requires: Array,
    successAction: {
      type: [Object, Array]
    },
    successState: {
      type: Array,
      default: () => []
    },
    endpoint: {
      type: String,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    styles: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    keepInState: Array
  },
  computed: {
    ...mapGetters(["customerToken", "campaignId", "receiptToken", "referenceNumber", "country", "jwtToken"])
  },
  methods: {
    clear () {
      this.fields.forEach(field => {
        if (field.fieldType === "text-input") {
          field.properties.text = "";
        } else if (field.fieldType === "select-input") {
          field.properties.selectedValue = "";
        } else if (field.fieldType === "radio-input") {
          field.properties.selectedValue = "";
        } else if (field.fieldType === "checkbox") {
          field.properties.checked = "";
        } else if (field.fieldType === "date-input") {
          field.properties.value = "";
        }
      });
    },
    getFieldData (fields, formData) {
      fields.forEach(field => {
        if (field.fieldType === "text-input") {
          const name = field.properties.name;
          let value = field.properties.text;
          // value = name === "iban" ? this.country + value : value
          formData[name] = name === "iban" ? value.replace(/\s/g, '') : value;
        } else if (field.fieldType === "select-input") {
          const name = field.properties.name;
          const value = field.properties.selectedValue;
          formData[name] = value;
        } else if (field.fieldType === "radio-input") {
          const name = field.properties.name;
          const value = field.properties.selectedValue;
          formData[name] = value;
        } else if (field.fieldType === "checkbox") {
          const name = field.properties.name;
          const value = field.properties.checked;
          formData[name] = value;
        } else if (field.fieldType === "date-input") {
          const name = field.properties.name;
          const value = field.properties.value;
          formData[name] = value;
        } else if (field.fieldType === "url-token") {
          const name = field.properties.name;
          const value = field.properties.text;
          formData[name] = value;
        } else if (field.fieldType === "checkbox-terms") {
          const name = field.properties.name;
          const value = field.properties.checked;
          formData[name] = value;
        } else if (field.properties.fields) {
          this.getFieldData(field.properties.fields, formData);
        }
      });
    },
    getFormData (formId) {
      if (this.fieldId === formId) {
        let formData = {};
        const endpoint = this.endpoint;
        if (this.locale) {
          formData.lang = this.locale;
        }
        formData.customerToken = this.customerToken;
        formData.campaignId = this.campaignId;
        formData.token = this.jwtToken;
        formData.receiptToken = this.receiptToken;
        this.getFieldData(this.fields, formData)
        // console.log("SENDING FORM>>", formData);
        this.$refs.form.validate().then(isValid => {
          if (isValid) {
            const presistentFormFields={}
            if(this.keepInState){
              this.keepInState.forEach(field=>{
                presistentFormFields[field] = this.$store.state.formData[field]
              })
            }
            this.$store.commit("setField", {
              field: "formData",
              value: { ...presistentFormFields,...formData }
            });
            console.log("SENDING FORM>>", formData);
            this.$store
              .dispatch("sendFormData", { formData, endpoint })
              .then(r => {
                if (r.consumerId !== undefined) {
                  this.$store.commit("setconsumerId", r.consumerId);
                }
                if (r.response && this.successAction) {
                  if (this.successAction.length > 1 ) {
                    for (let i = 0; i < this.successAction.length; i++) {
                      const act = this.successAction[i];
                      const fn = act.fn;
                      const params = act.params;
                      this[fn](params, this);
                    }
                  } else {
                    const fn = this.successAction.fn;
                    const params = this.successAction.params;
                    this[fn](params, this);
                  }
                  this.$eventHub.$emit("done-event-trigger", {
                    parent: this,
                    status: true
                  });
                }
                if (r.response && this.successState) {
                  this.successState.forEach(variable=>{
                    this.$store.commit("setField", { field: variable.state, value: r[variable.form] })
                  })
                }
                this.$eventHub.$emit("done-event-trigger", {
                  parent: this,
                  status: false,
                  message: r.message || "Unexpected Error",
                  invalid_fields: r.invalid_fields || []
                });

                if (r.response) {
                  // append response payload into formData
                  this.$store.commit("setField", {
                    field: "formData",
                    value: {
                      ...presistentFormFields,
                      ...formData,
                      ...r
                    }
                  });

                  if (this.fieldId == 'l0g1n' || this.fieldId == 'fr-l0g1n')
                    this.$store.commit("setReferenceNumber", r.referenceNumber)

                  if (this.fieldId == '56m7xm' || this.fieldId == 'fr-56m7xm')
                    this.$store.commit("setField", {
                      field: "formData",
                      value: {
                        "referenceNumber": this.referenceNumber
                      }
                    })
                }

              })
              .catch(e => {
                this.$eventHub.$emit("done-event-trigger", {
                  parent: this,
                  status: false
                });
              });
          } else {
            this.$eventHub.$emit("done-event-trigger", {
              parent: this,
              status: false
            });
            const element = document.getElementById(this.fieldId);
            if (element) {
              element.scrollIntoView({block: "start", behavior: "smooth"});
            }
          }
        });
      }
    },
    updated (field, properties) {
      let updatedField = this._.find(this.fields, function (fld) {
        return fld.fieldId === field.fieldId;
      });

      updatedField.properties = { ...updatedField.properties, ...properties };
      // let updatedField = __.findDeep(this.fields, fld => fld === field.fieldId);
      // console.log(
      //   "component renderer on updated::",
      //   field,
      //   "properties",
      //   properties,
      //   "FOUND",
      //   updatedField
      // );

      // TODO
      // some validation will go here
      // in case if all fields are valid we are happy to set truthy status
      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: true
      });
    }
  },
  mounted () {
    this.$eventHub.$on("getFormData-event", this.getFormData);
  }
};
</script>
<style scoped lang="scss">
.row {
  /deep/ [disabled] {
    opacity: 0.65;
    pointer-events: none;
  }
}
</style>
