<template>
  <div class="wrapper">
    <div
      v-for="(step, idx) in steps"
      :key="idx"
      class="step-wrapper"
      :class="{ active: step.active, done: step?.done}"
    >
      <div class="circle-wrapper">
        <div class="number-wrapper">
          <span v-if="!step.done">{{ idx + 1 }}</span>
          <img v-else src="@/assets/images/svg/done.svg" alt="Done">
        </div>
      </div>
      <div class="text-wrapper">
        {{ step.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    stepCount: {
      type: Number,
      default: 3
    },
    steps: {
      type: Array,
      default: () => []
    }
  },
  methods: {
  }
};
</script>

<style lang="scss">
@import "@/mixins/style/_color";
:root {
  --circle-width: 40px;
  --circle-height: 40px;

  @media (max-width: 880px) {
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .step-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    .circle-wrapper {
      position: relative;
      width: var(--circle-width);
      height: var(--circle-height);
      border-radius: 50%;
      background: $color-1;
      border: 2px solid #EFF0F6;
      display: flex;
      justify-content: center;
      align-items: center;
      color:  #EFF0F6;
      font-size: 16px;
      font-weight: 600;
      z-index: 10;
    }

    .text-wrapper {
      font-size: 14px;
      font-weight: 600;
      color: #EFF0F6;
      margin-top: 10px;
      text-align: center;
    }
  }

  .step-wrapper:not(:last-child) {
    .circle-wrapper::after {
      content: '';
      position: absolute;
      left: 40px;
      top: 50%;
      width: 13vw;
      border-bottom: 2px dotted white;
      z-index: -1;
      @media (max-width: 1320px) {
        width: 16vw;
      }
      @media (max-width: 1120px) {
        width: 18vw;
      }
      @media (max-width: 767px) {
        width: 13vw;
      }
      @media (max-width: 575px) {
        width: 24vw;
      }
      @media (max-width: 450px) {
        width: 20vw;
      }
    }

  }

  .done {
    .circle-wrapper {
      background: #FFF;
      border: 2px solid #FFF;
    }
  }

  .active {
    .circle-wrapper {
      &::after {
        border-bottom: 2px solid #FFF !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .step-wrapper {
    max-width: 75px !important;
  }
  .text-wrapper {
    display: none;
  }
}

</style>

