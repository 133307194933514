
export default {
  isEditMode: true,
  pageProperties: {
    testing: true,
    "pages": [
      { // Home Page
        "pageName": "home",
        isPublic: true,
        pageId: 100,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "sectionId": "fu30zq",
            "classes": "home-page",
            "fields": [
              // { // Refresh Token
              //   "fieldType": "refresh-token",
              //   "fieldId": "ij3433"
              // },
              { // Key Visual
                "fieldType": "key-visual",
                "fieldId": "296eg1",
                "properties": {
                  "grid": {
                    "columns": "12",
                    "marginBottom": "40px"
                  },
                  "noContainer": true,
                  "desktopImageUrl": "assets/images/banner/banner-desktop.png",
                  "tabletImageUrl": "assets/images/banner/banner-desktop.png",
                  "mobileImageUrl": "assets/images/banner/banner-mobile.png"
                }
              },
              // {
              //   "fieldType": "empty-bucket",
              //   "fieldId": "fu0923r",
              //   "properties": {
              //     "grid": {
              //       "columns": 12,
              //       "alignItems": "center",
              //       "justifyContent": "center",
              //       "marginBottom": "40px",
              //       "width": "100%"
              //     },
              //     "fields": [
              //       {
              //         "fieldType": "campaign-disclaimer",
              //         "fieldId": "disclaimer",
              //         "properties": {
              //           "grid": {
              //             "columns": "10"
              //           },
              //           "dates": "15.02.2024 - 30.04.2024",
              //           "text1": "Einsendeschluss für das Onlineformular ist der 30.04.2024",
              //           "text2": "Ein Teilnehmer kann mit maximal einem Produkt teilnehmen. 100% Rückerstattung",
              //         }
              //       },
              //     ]
              //   }
              // },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  classes: "container",
                  fields: [
                    {
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "40px",
                          marginTop: "40px"
                        },
                        text: "Schritte zur Teilnahme an der Aktion",
                        classes: 'title text-center'
                      }
                    },
                    { // Steps Card
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "marginTop": "60px",
                          "marginBottom": "60px",
                          "display": "flex",
                          "flex-direction": "row",
                          "justify-content": "center",
                          "columnGap": "40px"
                        },
                        "class": "home-steps",
                        "fields": [
                          {
                            "fieldType": "steps-horizontal",
                            "fieldId": "steps",
                            "properties": {
                              "grid": {
                                "columns": 12
                              },
                              colorSchema: "#FCFCFC",
                              steps: [
                                {
                                  stepText: "Produkt kaufen",
                                  icon: {
                                    src: "assets/images/steps/step-1.png",
                                    alt: "step1"
                                  }
                                },
                                {
                                  stepText: "Kaufbeleg hochladen",
                                  icon: {
                                    src: "assets/images/steps/step-2.png",
                                    alt: "step2"
                                  }
                                },
                                {
                                  stepText: "Geld zurück erhalten",
                                  icon: {
                                    src: "assets/images/steps/step-3.png",
                                    alt: "step3"
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Button
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "marginTop": "40px",
                          'justify-content': 'center'
                        },
                        "fields": [
                          {
                            "fieldType": "button-component",
                            "fieldId": "x1p9hx",
                            "properties": {
                              "grid": {
                                "columns": 5
                              },
                              "text": "Rückerstattung starten",
                              "classes": "btn-primary text-uppercase",
                              "disabled": false,
                              "startCampaignButton": true,
                              "styles": {
                                "border-radius": "30px"
                              },
                              "action": [
                                {
                                  "fn": "goToPage",
                                  "params": "products"
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // products
        "pageName": "products",
        pageId: 10011,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Progress Bar
                      "fieldType": "progress-bar",
                      "fieldId": "progressBar",
                      "properties": {
                        grid: {
                          columns: 10,
                          marginBottom: "50px"
                        },
                        "steps": [
                          {
                            "label": "Produkt kaufen",
                            "done": true
                          },
                          {
                            "label": "Kaufbeleg hochladen",
                            "active": false
                          },
                          {
                            "label": "Teilnahmeformular ausfüllen",
                            "active": false
                          },
                          {
                            "label": "Kontodaten eingeben",
                            "active": false
                          },
                        ]
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Produkt auswählen",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: `Bitte wählen Sie das Aktionsprodukt aus, mit dem Sie teilnehmen möchten.`,
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Barcode scanner
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "alignItems": "center",
                          "justifyContent": "space-around",
                          "marginBottom": "60px"
                        },
                        "classes": "receipt-bucket",
                        "fields": [
                          { // Products
                            fieldType: "product-selection",
                            fieldId: "prodSelection",
                            properties: {
                              grid: {
                                columns: 12,
                              },
                              texts: {
                                required: "Bitte wählen Sie mindestens ein Produkt aus.",
                                maxSelectable: "Sie können nicht mehr als ein Produkt auswählen."
                              },
                              colorSchema: "#fff",
                              maxSelectable: 1,
                              successAction: {
                                fn: "goToPage",
                                params: "receipt-upload"
                              },
                              endpoint: "/submitProducts",
                              styles: {
                                alignItems: "center;",
                                justifyContent: "space-between;"
                              },
                              products: [
                                {
                                  id: 0,
                                  ean: 8006540711569,
                                  title: "Pampers Night Pants Größe 4",
                                  backgroundImage: "assets/images/png/8006540711569.png",
                                  selectable: true
                                },
                                {
                                  id: 1,
                                  ean: 8006540711910,
                                  title: "Pampers Night Pants Größe 5",
                                  backgroundImage: "assets/images/png/8006540711910.png",
                                  selectable: true
                                },
                                {
                                  id: 2,
                                  ean: 8006540710975,
                                  title: "Pampers Night Pants Größe 6",
                                  backgroundImage: "assets/images/png/8006540710975.png",
                                  selectable: true
                                },
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Weiter",
                              classes: "btn-primary",
                              "requires": ["prodSelection"],
                              "action": {
                                "fn": "sendProducts",
                                "params": ""
                              }
                            }
                          },
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Receipt Upload
         "pageName": "receipt-upload",
         pageId: 10099,
         "sections": [
           { // Steps and Header
             "grid": {
               "columns": 12,
               "alignItems": "center",
               "justifyContent": "center",
               "marginTop": "50px"
             },
             "sectionId": "fu30zq",
             "fields": [
               { // Container
                 "fieldId": "fu0923r",
                 "fieldType": "empty-bucket",
                 "properties": {
                   "grid": {
                     "columns": 12,
                     "alignItems": "center",
                     "justifyContent": "center",
                     "marginBottom": "60px"
                   },
                   "classes": "container",
                   fields: [
                     { // Progress Bar
                        "fieldType": "progress-bar",
                        "fieldId": "progressBar",
                        "properties": {
                          grid: {
                            columns: 10,
                            marginBottom: "50px"
                          },
                          "steps": [
                            {
                              "label": "Produkt kaufen",
                              "done": true,
                              "active": true
                            },
                            {
                              "label": "Kaufbeleg hochladen",
                              "done": true
                            },
                            {
                              "label": "Teilnahmeformular ausfüllen",
                              "active": false
                            },
                            {
                              "label": "Kontodaten eingeben",
                              "active": false
                            },
                          ]
                        }
                     },
                     { // Heading
                       "fieldType": "paragraph",
                       "fieldId": "par1001",
                       properties: {
                         grid: {
                           columns: 12,
                           marginBottom: "20px"
                         },
                         text: "Kaufbeleg + Produktrückseite mit Barcode hochladen",
                         classes: 'heading text-left'
                       }
                     },
                     { // Subheading
                       "fieldType": "paragraph",
                       "fieldId": "par1001",
                       properties: {
                         grid: {
                           columns: 12,
                           marginBottom: "50px"
                         },
                         text: `Bitte laden Sie in diesem Schritt den Kaufbeleg Ihres Produktes, gemeinsam mit der Rückseite Ihres Produktes hoch. Bitte beachten Sie, dass der Barcode und das Aktionsprodukt gut ersichtlich sein müssen.`,
                         classes: 'subHeading text-left'
                       }
                     },
                     { // Receipt uploader
                       "fieldType": "empty-bucket",
                       "fieldId": "h453eadaf",
                       "properties": {
                         "grid": {
                           "columns": 12,
                           "alignItems": "center",
                           "justifyContent": "space-around",
                           "marginBottom": "60px"
                         },
                         "classes": "receipt-bucket",
                         "fields": [
                           { // Recipt upload
                             "fieldType": "receipt-processor",
                             "fieldId": "klvakj",
                             "properties": {
                               "grid": {
                                 "columns": 5,
                                 "alignItems": "center",
                                 "justifyContent": "center"
                               },
                               "successAction": {
                                 "fn": "goToPage",
                                 "params": "guest-register"
                               },
                               texts: {
                                 choose: 'Upload',
                                 text1: 'Drop your photo here or select file.',
                                 text2: 'Only JPG, PNG and PDF files are supported Maximum file size: 10MB',
                                 wrongFormat: 'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.',
                                 fileSizeLimit: 'The file is too large. Only files up to 10MB are supported.'
                               },
                               editTexts: {
                                 crop: "Crop",
                                 erase: "Erase",
                                 drag: "Drag",
                                 zoomIn: "Zoom in",
                                 zoomOut: "Zoom out"
                               },
                               colorSchema: "#DF1B25",
                               "phrases": [
                                 "Please erase your personal data with the eraser tool.",
                                 "Please frame the receipt / invoice in the gray rectangle."
                               ],
                               "cornerDetectionPhrases": [
                                 "Please erase your personal data with the eraser tool.",
                                 "Please frame the receipt / invoice in the gray rectangle."
                               ]
                             }
                           },
                           { // Receipt Info
                             "fieldType": "empty-bucket",
                             "fieldId": "vdf932fd",
                             "properties": {
                               "grid": {
                                 "columns": 7
                               },
                               "fields": [
                                 {
                                   "fieldType": "receipt-information",
                                   "fieldId": "5mq1p6",
                                   "properties": {
                                     "grid": {
                                       "alignItems": "center",
                                       "justifyContent": "center",
                                       "columns": 12
                                     },
                                     shouldDisplayFull: true,
                                     "texts": {
                                       "descText": `Die folgenden Informationen müssen deutlich lesbar sein.`,
                                       "descSubText": `Gefaltete oder eingescannte Belege oder Rechnungen werden nicht akzeptiert.`,
                                       "correct": "Valid",
                                       "notReadabe": "Hard to read",
                                       "folded": "Folded",
                                       "cropped": "Cut off"
                                     },
                                     "hideExamples": false,
                                     "imageSrc": "assets/images/png/receipt-product.png",
                                     "steps": [
                                       { // Retailer
                                         "text": "Retailer / Shop",
                                         "label": "A"
                                       },
                                       {
                                         "text": "Campaign product and its price",
                                         "label": "B"
                                       },
                                       {
                                         "text": "Total sum of the receipt / invoice and applied VAT",
                                         "label": "C"
                                       },
                                       {
                                         "text": "Date and time of purchase",
                                         "label": "D"
                                       },
                                       {
                                         "text": "Receipt / Invoice ID (not the bank transaction ID)",
                                         "label": "E"
                                       },
                                       {
                                         "text": "Barcode-Number",
                                         "label": "F"
                                       },
                                     ]
                                   }
                                 }
                               ]
                             }
                           }
                         ]
                       }
                     },
                     { // Buttons
                       "fieldId": "fu0923r",
                       "fieldType": "empty-bucket",
                       "properties": {
                         "grid": {
                           "columns": "12",
                           "marginBottom": "30px"
                         },
                         classes: "btn-bucket",
                         fields: [
                          { // Back Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Zurück",
                              classes: "btn-secondary back",
                              "action": {
                                "fn": "goToPage",
                                "params": "products"
                              }
                            }
                          },
                           { // Continue Button
                             "fieldType": "button-component",
                             "fieldId": "but100",
                             properties: {
                               grid: {
                                 columns: 3
                               },
                               text: "Weiter",
                               classes: "btn-primary",
                               "requires": ["klvakj"],
                               "action": {
                                 "fn": "sendReceipt",
                                 "params": ""
                               }
                             }
                           },
                         ]
                       }
                     }
                   ]
                 }
               }
             ]
           }
         ]
      },
      { // Guest Register
        "pageName": "guest-register",
        pageId: 1204,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Progress Bar
                      "fieldType": "progress-bar",
                      "fieldId": "progressBar",
                      "properties": {
                        grid: {
                          columns: 10,
                          marginBottom: "50px"
                        },
                        "steps": [
                          {
                            "label": "Produkt kaufen",
                            "done": true,
                            "active": true
                          },
                          {
                            "label": "Kaufbeleg hochladen",
                            "done": true,
                            "active": true
                          },
                          {
                            "label": "Teilnahmeformular ausfüllen",
                            "active": true
                          },
                          {
                            "label": "Kontodaten eingeben",
                            "active": false
                          },
                        ]
                      }
                   },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Teilnahmeformular",
                        classes: 'heading text-left'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Zur Zahlungsabwicklung brauchen wir noch die folgenden Informationen von Ihnen. Diese werden nur für die Abwicklung der Pampers Night Pants Aktion verwendet.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Register Form
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "columnGap": "20px",
                          "marginBottom": "50px"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 10,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              classes: "register-page-bucket",
                              "fields": [
                                {
                                  "fieldType": "form-component",
                                  "fieldId": "guestForm",
                                  "properties": {
                                    "grid": {
                                      "columns": 12,
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "marginBottom": "50px",
                                      "padding": "20px"
                                    },
                                    "endpoint": "/register",
                                    "successAction": {
                                      "fn": "goToPage",
                                      "params": "bank"
                                    },
                                    "fields": [
                                      {
                                        "fieldId": "fu2309b",
                                        "fieldType": "empty-bucket",
                                        "properties": {
                                          "grid": {
                                            "columns": 12
                                          },
                                          "classes": "form-wrapper",
                                          "fields": [
                                            {
                                              "fieldType": "paragraph",
                                              "fieldId": "yh54weg",
                                              "properties": {
                                                "text": "Über Sie",
                                                "grid": {
                                                  "alignItems": "center",
                                                  "justifyContent": "center",
                                                  "columns": "12"
                                                },
                                                "classes": "form-title"
                                              }
                                            },
                                            {
                                              "fieldId": "nameWrapper",
                                              "fieldType": "empty-bucket",
                                              "properties": {
                                                "grid": {
                                                  "columns": 12,
                                                  "columnGap": "10px",
                                                  "flexWrap": "nowrap",
                                                },
                                                classes: "inputs-bucket",
                                                "fields": [
                                                  {
                                                    "fieldId": "b4baqs",
                                                    "fieldType": "text-input",
                                                    "properties": {
                                                      "name": "firstName",
                                                      "id": "firstName",
                                                      "label": "Vorname",
                                                      "placeholder": "Vorname",
                                                      "text": "",
                                                      labelOnTop: true,
                                                      "validation": "required|namesWithHyphensAndSpaces",
                                                      "grid": {
                                                        "columns": 6
                                                      }
                                                    }
                                                  },
                                                  {
                                                    "fieldId": "gofije",
                                                    "fieldType": "text-input",
                                                    "properties": {
                                                      "name": "lastName",
                                                      "id": "lastName",
                                                      "label": "Nachname",
                                                      "placeholder": "Nachname",
                                                      "text": "",
                                                      labelOnTop: true,
                                                      "validation": "required|namesWithHyphensAndSpaces",
                                                      "grid": {
                                                        "columns": 6
                                                      }
                                                    }
                                                  }
                                                ]
                                              }
                                            },
                                            {
                                              "fieldId": "nameWrapper",
                                              "fieldType": "empty-bucket",
                                              "properties": {
                                                "grid": {
                                                  "columns": 12,
                                                  "columnGap": "10px",
                                                  "flexWrap": "nowrap",
                                                },
                                                classes: "inputs-bucket",
                                                "fields": [
                                                  {
                                                    "fieldId": "x3d8oz",
                                                    "fieldType": "select-input",
                                                    "properties": {
                                                      "name": "gender",
                                                      "id": "gender",
                                                      "label": "Anrede",
                                                      "placeholder": "Anrede",
                                                      "text": "",
                                                      selectedValue: "other",
                                                      labelOnTop: true,
                                                      "validation": "",
                                                      "options": [
                                                        {
                                                          "text": "Herr",
                                                          "value": "male"
                                                        },
                                                        {
                                                          "text": "Frau",
                                                          "value": "female"
                                                        },
                                                        {
                                                          "text": "Divers",
                                                          "value": "miscellaneous"
                                                        },
                                                        {
                                                          "text": "Keine Angabe",
                                                          "value": "other"
                                                        }
                                                      ],
                                                      "grid": {
                                                        "columns": 6
                                                      }
                                                    }
                                                  },
                                                  {
                                                    "fieldId": "h7kzf9",
                                                    "fieldType": "text-input",
                                                    "properties": {
                                                      "name": "email",
                                                      "id": "email",
                                                      "label": "E-mail",
                                                      "placeholder": "example@example.com",
                                                      "text": "",
                                                      labelOnTop: true,
                                                      "validation": "required|email",
                                                      "grid": {
                                                        "columns": 6
                                                      }
                                                    }
                                                  }
                                                ]
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      { // Checkboxes
                                        "fieldId": "8k2f023f",
                                        "fieldType": "empty-bucket",
                                        "properties": {
                                          "grid": {
                                            "columns": "12"
                                          },
                                          "fields": [
                                            {
                                              "fieldType": "checkbox",
                                              "fieldId": "wgc3j8",
                                              "properties": {
                                                "name": "campaignTermsAccepted",
                                                "id": "campaignTermsAccepted",
                                                "validation": {
                                                  "mandatory": {
                                                    "allowFalse": false
                                                  }
                                                },
                                                "grid": {
                                                  "columns": "12",
                                                  "paddingTop": "10px"
                                                },
                                                colorSchema: "#4E4B66",
                                                "label": "Ich habe die <a href='/teilnahmebedingungen' target='_blank'>Teilnahmebedingungen</a> dieser Aktion gelesen und akzeptiere diese."
                                              }
                                            },
                                            {
                                              "fieldType": "checkbox",
                                              "fieldId": "wgc3j7",
                                              "properties": {
                                                "name": "terms",
                                                "id": "terms",
                                                "validation": {
                                                  "mandatory": {
                                                    "allowFalse": false
                                                  }
                                                },
                                                "grid": {
                                                  "columns": "12",
                                                  "paddingTop": "10px"
                                                },
                                                colorSchema: "#4E4B66",
                                                "label": `Ich bestätige, dass ich über 18 Jahre alt bin, und die <a href="https://termsandconditions.pg.com/de-de/" target="_blank"><u><b>Geschäftsbedingungen</b></u></a>, <a href="http://www.pg.com/privacy/german/privacy_statement.shtml" target="_blank"><u><b>Datenschutzrichtlinien</b></u></a> &amp; <a href="https://justsnap.de/Datenschutz" target="_blank"><u><b>Justsnap Datenschutzrichtlinien</b></u></a> akzeptiere.
                                                <br>
                                                Sie können Ihre <b>Datenschutzrechte</b> ausüben oder ihre Einstellungen und Abonnements im Bereich <a href='https://preferencecenter.pg.com/de-de/' target='_blank'>Meine Anfrage zum Datenschutz</a> ändern.`
                                              }
                                            },
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Back Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Zurück",
                              classes: "btn-secondary back",
                              "action": {
                                "fn": "goToPage",
                                "params": "receipt-upload"
                              }
                            }
                          },
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Weiter",
                              classes: "btn-primary",
                              "action": {
                                "fn": "sendForm",
                                "params": "guestForm"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // IBAN
        "pageName": "bank",
        pageId: 12031,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Progress Bar
                      "fieldType": "progress-bar",
                      "fieldId": "progressBar",
                      "properties": {
                        grid: {
                          columns: 10,
                          marginBottom: "50px"
                        },
                        "steps": [
                          {
                            "label": "Produkt kaufen",
                            "done": true,
                            "active": true
                          },
                          {
                            "label": "Kaufbeleg hochladen",
                            "done": true,
                            "active": true
                          },
                          {
                            "label": "Teilnahmeformular ausfüllen",
                            "done": true,
                            "active": true
                          },
                          {
                            "label": "Kontodaten eingeben",
                            "active": true
                          },
                        ]
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Bankverbindung angeben",
                        classes: 'heading text-left'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Bitte teilen Sie uns mit, auf welches Konto der Erstattungsbetrag überwiesen werden soll.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Register Form
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "columnGap": "20px",
                          "marginBottom": "50px"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              classes: "register-page-bucket",
                              "fields": [
                                {
                                  "fieldType": "form-component",
                                  "fieldId": "ibanForm",
                                  "properties": {
                                    "grid": {
                                      "columns": 12,
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "marginBottom": "50px",
                                      "padding": "20px"
                                    },
                                    "endpoint": "/submitIban",
                                    "successAction": {
                                      "fn": "goToPage",
                                      "params": "sms"
                                    },
                                    "fields": [
                                      {
                                        "fieldId": "fu2309b",
                                        "fieldType": "empty-bucket",
                                        "properties": {
                                          "grid": {
                                            "columns": 12,
                                            "marginBottom": "20px"
                                          },
                                          "classes": "form-wrapper",
                                          "fields": [
                                            {
                                              "fieldId": "b4baqs",
                                              "fieldType": "text-input",
                                              "properties": {
                                                "name": "firstname",
                                                "id": "firstname",
                                                "label": "Vorname Kontoinhaber",
                                                "placeholder": "Vorname Kontoinhaber",
                                                "text": "",
                                                labelOnTop: true,
                                                "validation": "required|ibanName",
                                                "grid": {
                                                  "columns": 12
                                                }
                                              }
                                            },
                                            {
                                              "fieldId": "gofije",
                                              "fieldType": "text-input",
                                              "properties": {
                                                "name": "lastname",
                                                "id": "lastname",
                                                "label": "Nachname Kontoinhaber",
                                                "placeholder": "Nachname Kontoinhaber",
                                                "text": "",
                                                labelOnTop: true,
                                                "validation": "required|ibanName",
                                                "grid": {
                                                  "columns": 12
                                                }
                                              }
                                            },
                                            {
                                              "fieldId": "b4baqq",
                                              "fieldType": "text-input",
                                              "properties": {
                                                "name": "iban",
                                                "id": "iban",
                                                "label": "IBAN",
                                                "placeholder": "IBAN",
                                                "text": "",
                                                labelOnTop: true,
                                                "validation": "required|iban",
                                                "grid": {
                                                  "columns": 12
                                                }
                                              }
                                            },
                                            {
                                              "fieldId": "gofijx",
                                              "fieldType": "text-input",
                                              "properties": {
                                                "name": "bic",
                                                "id": "bic",
                                                "label": "BIC",
                                                "placeholder": "BIC",
                                                "text": "",
                                                labelOnTop: true,
                                                "validation": "required|bic",
                                                "grid": {
                                                  "columns": 12
                                                }
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        "fieldType": "paragraph",
                                        "fieldId": "par1001",
                                        "properties": {
                                          "grid": {
                                            "columns": 12,
                                            "marginBottom": "20px"
                                          },
                                          "text": '* Vermeiden Sie eine Änderung bei den Umlauten: <b>Müller bzw. Mueller.</b> Vermeiden Sie ein Hinzufügen oder Weglassen von Bindestrichen: <b>Marie Luise bzw. Marie-Luise.</b> Geben Sie den zweiten Vornamen nur dann an, wenn auch das Konto so geführt wird. Sollten die Daten nicht mit den Kontodaten übereinstimmen, kommt es in den meisten Fällen zu Rückbuchungen seitens der Bank und erschwert den Ablauf einer unkomplizierten und raschen Rückerstattung.',
                                          classes: 'subHeading text-left',
                                          styles: {
                                            "font-size": "14px",
                                            "font-weight": "400"
                                          }
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Back Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Zurück",
                              classes: "btn-secondary back",
                              "action": {
                                "fn": "goToPage",
                                "params": "guest-register"
                              }
                            }
                          },
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Weiter",
                              classes: "btn-primary",
                              "action": {
                                "fn": "sendForm",
                                "params": "ibanForm"
                              }
                            }
                          }
                        ]
                      }
                    },
                  ]
                }
              }
            ]
          }
        ]
      },
      { // SMS
        "pageName": "sms",
        pageId: 1205,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    {
                      "fieldType": "square-image",
                      "fieldId": "image",
                      "properties": {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        "imageSource": "assets/images/svg/lock.svg",
                        "alternateText": "SMS",
                        "classes": "sms-image",
                        "styles": {
                          "max-width": "130px"
                        }
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Bitte bestätigen Sie Ihre Identität",
                        classes: 'heading text-center'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 6,
                          marginBottom: "50px"
                        },
                        text: "Die Angabe der Mobilrufnummer ist zur Verifizierung Ihrer Teilnahme erforderlich. Die von Ihnen angegebene Mobilrufnummer wird ausschließlich zur Verifizierung verwendet und wird nach der Aktion von uns gelöscht.",
                        classes: 'subHeading text-center'
                      }
                    },
                    { // Register Form
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "columnGap": "20px",
                          "marginBottom": "50px"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 10,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              "fields": [
                                {
                                  "fieldType": "sms-verification",
                                  "fieldId": "smsVer",
                                  "properties": {
                                    "grid": {
                                      "columns": 6,
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "marginBottom": "50px"
                                    },
                                    "successAction": {
                                      "fn": "goToPage",
                                      "params": "code"
                                    },
                                    "options": [
                                      {
                                        "name": "Deutschland",
                                        "code": "DE",
                                        "phoneCode": "+49"
                                      },
                                      {
                                        "name": "Schweiz",
                                        "code": "CH",
                                        "phoneCode": "+41"
                                      },
                                      {
                                        "name": "Österreich",
                                        "code": "AT",
                                        "phoneCode": "+43"
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Weiter",
                              classes: "btn-primary",
                              "action": {
                                "fn": "sendSms",
                                "params": ""
                              }
                            }
                          }
                        ]
                      }
                    },
                  ]
                }
              }
            ]
          }
        ]
      },
      { // SMS Code
        "pageName": "code",
        pageId: 1206,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    {
                      "fieldType": "square-image",
                      "fieldId": "image",
                      "properties": {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        "imageSource": "assets/images/svg/lock.svg",
                        "alternateText": "SMS",
                        "classes": "sms-image",
                        "styles": {
                          "max-width": "130px"
                        }
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Bitte bestätigen Sie Ihre Identität",
                        classes: 'heading text-center'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 6,
                          marginBottom: "50px"
                        },
                        text: "Die Angabe der Mobilrufnummer ist zur Verifizierung Ihrer Teilnahme erforderlich. Die von Ihnen angegebene Mobilrufnummer wird ausschließlich zur Verifizierung verwendet und wird nach der Aktion von uns gelöscht.",
                        classes: 'subHeading text-center'
                      }
                    },
                    { // Register Form
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "columnGap": "20px",
                          "marginBottom": "50px"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 10,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              "fields": [
                                {
                                  "fieldType": "sms-receiving",
                                  "fieldId": "smsVer",
                                  "properties": {
                                    "grid": {
                                      "columns": 4,
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "marginBottom": "50px"
                                    },
                                    "successAction": {
                                      "fn": "goToPage",
                                      "params": "thank-you"
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Back Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Zurück",
                              classes: "btn-secondary back",
                              "action": {
                                "fn": "goToPage",
                                "params": "sms"
                              }
                            }
                          },
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Weiter",
                              classes: "btn-primary",
                              "action": {
                                "fn": "verifySms",
                                "params": ""
                              }
                            }
                          }
                        ]
                      }
                    },
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Thank you Page
        "pageName": "thank-you",
        pageId: 1207,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center"
                        },
                        "fields": [
                          { // Heading
                            "fieldType": "paragraph",
                            "fieldId": "par1001",
                            properties: {
                              grid: {
                                columns: 12,
                                marginBottom: "20px"
                              },
                              text: "Danke für die Teilnahme",
                              classes: 'heading text-left'
                            }
                          },
                          { // Heading
                            "fieldType": "paragraph",
                            "fieldId": "par1001",
                            properties: {
                              grid: {
                                columns: 12,
                                marginBottom: "50px"
                              },
                              text: "Ihr Kassenbeleg und Ihre Daten wurden erfolgreich an uns übermittelt und werden jetzt überprüft.",
                              classes: 'subHeading text-left'
                            }
                          },
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 12,
                                "justifyContent": "center",
                                "alignItems": "flex-start",
                                "flexDirection": "column",
                                "paddingLeft": "0",
                                "marginLeft": "0"
                              },
                              "fields": [
                                { // Clipboard
                                  "fieldType": "clipboard",
                                  "fieldId": "ref123",
                                  "properties": {
                                    "grid": {
                                      "columns": 12,
                                      "marginBottom": "30px",
                                      "paddingLeft": "0",
                                      "marginLeft": "0"
                                    },
                                    "title": "Referenznummer:",
                                    colorSchema: "#3A855C",
                                    "variables": {
                                      "referenceNumber": "referenceNumber"
                                    }
                                  }
                                },
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "20px",
                                      paddingLeft: "0",
                                      marginLeft: "0"
                                    },
                                    text: "Sobald wir Ihre Daten überprüft haben, bekommen Sie entweder eine Bestätigungs-E-Mail oder eine E-Mail mit Rückfragen, falls Ihre Daten unvollständig waren oder Ihr Kaufbeleg nicht erfasst werden konnte. Dies kann bis zu 36 Stunden in Anspruch nehmen. Wir bitten um Ihre Geduld. Nach erfolgreicher Überprüfung des Kaufbelegs und aller weiteren Daten, überweisen wir den Erstattungsbetrag innerhalb von 6 Wochen auf das von Ihnen angegebene Konto. Sollten sich Unklarheiten ergeben, werden wir Sie per E-Mail nochmal separat kontaktieren.",
                                    classes: 'text text-left',
                                    styles: {
                                      "font-size": "19px",
                                      "color" : "#FCFCFC",
                                      "font-weight": "400"
                                    }
                                  }
                                },
                              ]
                            }
                          },
                          { // Heading
                            "fieldType": "paragraph",
                            "fieldId": "par1001",
                            properties: {
                              grid: {
                                columns: 0,
                                marginBottom: "0px"
                              },
                              text: "Entdecke jetzt den Pampers Club!",
                              classes: "pampers-btn"
                            }
                          },
                          { // Subheading
                            "fieldType": "paragraph",
                            "fieldId": "par1001",
                            properties: {
                              grid: {
                                columns: 12,
                                marginBottom: "50px",
                                paddingLeft: "0",
                                marginLeft: "0"
                              },
                              text: `<center>
                                Hier klicken für die App:
                                <br>
                                <a class="link-img" href="https://app.adjust.com/zn2kx1f_lj0ajbu?campaign=NightPants&adgroup=Cashback DE&creative=2024-01" target="_blank"><img src='/assets/images/de.png' width="40px" /></a>
                                <a class="link-img" href="https://app.adjust.com/14nj33th?campaign=NightPants&adgroup=Cashback AT&creative=2024-01" target="_blank"><img src='/assets/images/at.png' width="40px" /></a>
                              </center>
                              <h1 class="heading mt-2"><center>Kostenloses Treueprogramm von Pampers:</center></h1>
                              Wusstest ihr, dass sich in jeder Windelpackung von Pampers ein Code befindet? Mit der Pampers Club App lassen sich – durch das Scannen eines Codes Punkte sammeln. Gesammelte Punkte lassen sich dann in Pampers Rabatte und Produkte eintauschen. Auf diesem Weg könnt ihr euch Gratis-Windeln verdienen und Geld sparen.
                              <br>
                              In der App gibt es außerdem einen Windelgrößen-Rechner und viele wertvolle Infos zu Elternschaft & Schwangerschaft sowie Pampers Elternratgeber – von Schwangerschaftstipps über Ideen für eine Babyparty, Babynamen, Geschenkideen für Babys, Spieltipps und vieles mehr.`,
                              classes: 'text text-left',
                              styles: {
                                "font-size": "19px",
                                "color" : "#FCFCFC",
                                "font-weight": "400"
                              }
                            }
                          },
                          { // Key Visual
                            "fieldType": "key-visual",
                            "fieldId": "296eg1",
                            "properties": {
                              "grid": {
                                "columns": "12"
                              },
                              "noContainer": true,
                              "desktopImageUrl": "assets/images/png/app-ty.png",
                              "tabletImageUrl": "assets/images/png/app-ty.png",
                              "mobileImageUrl": "assets/images/png/app-ty.png"
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Terms Page
        "pageName": "teilnahmebedingungen",
        isPublic: true,
        pageId: 1217,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    { // Embed component
                      "fieldType": "pdf-viewer",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        link: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/pampers-pants-gratis-testen/terms.pdf',
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // FAQ Page
        "pageName": "faq",
        isPublic: true,
        pageId: 1218,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    { // Embed component
                      "fieldType": "pdf-viewer",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        link: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/pampers-pants-gratis-testen/faq.pdf',
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Campaign is Over
        "pageName": "campaign-is-over",
        isPublic: true,
        pageId: 1208,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "marginBottom": "80px",
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 7,
                                "justifyContent": "center",
                                "alignItems": "flex-start",
                                "flexDirection": "column"
                              },
                              "fields": [
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "20px"
                                    },
                                    text: "Der Aktionszeitraum ist vorbei",
                                    classes: 'heading text-left'
                                  }
                                },
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "50px"
                                    },
                                    text: "Registrieren Sie sich, um auf dem aktuellsten Stand zu bleiben. Erhalten Sie Sonderangebote und Updates.",
                                    classes: 'subHeading text-left'
                                  }
                                },
                              ]
                            }
                          },
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 5,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              "fields": [
                                {
                                  "fieldType": "square-image",
                                  "fieldId": "image",
                                  "properties": {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "50px"
                                    },
                                    "imageSource": "assets/images/png/expired.png",
                                    "alternateText": "SMS",
                                    "classes": "sms-image",
                                    "styles": {
                                      "max-width": "400px"
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Zurück zur PAMPERS website",
                        "grid": {
                          "columns": 6,
                          "alignItems": "center",
                          "justifyContent": "center",
                          marginBottom: "50px"
                        },
                        "classes": "btn-primary",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "https://www.pampers.de/"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Error
        "pageName": "error",
        isPublic: true,
        pageId: 12082,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "marginBottom": "80px",
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 7,
                                "justifyContent": "center",
                                "alignItems": "flex-start",
                                "flexDirection": "column"
                              },
                              "fields": [
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "20px"
                                    },
                                    text: "Oops...",
                                    classes: 'heading text-left',
                                    styles: {
                                      "font-size": "56px",
                                    }
                                  }
                                },
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "50px"
                                    },
                                    text: "Hoppla, da ist etwas schief gelaufen. Bitte versuchen Sie es erneut.",
                                    classes: 'subHeading text-left'
                                  }
                                },
                              ]
                            }
                          },
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 5,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              "fields": [
                                {
                                  "fieldType": "square-image",
                                  "fieldId": "image",
                                  "properties": {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "50px"
                                    },
                                    "imageSource": "assets/images/png/error.png",
                                    "alternateText": "SMS",
                                    "classes": "sms-image",
                                    "styles": {
                                      "max-width": "400px"
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Zurück zur PAMPERS website",
                        "grid": {
                          "columns": 6,
                          "alignItems": "center",
                          "justifyContent": "center",
                          marginBottom: "50px"
                        },
                        "classes": "btn-primary",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "https://www.pampers.de/"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Expired
        "pageName": "expired",
        isPublic: true,
        pageId: 1210,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "marginBottom": "80px",
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 7,
                                "justifyContent": "center",
                                "alignItems": "flex-start",
                                "flexDirection": "column"
                              },
                              "fields": [
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "20px"
                                    },
                                    text: "Diese Sitzung ist abgelaufen",
                                    classes: 'heading text-left'
                                  }
                                },
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "50px"
                                    },
                                    text: `Leider ist der Link nicht mehr gültig! Bitte nehmen Sie erneut teil oder kontaktieren Sie unser Support Team: <a href='mailto:kontakt@pampers-pants-gratis-testen.de'>kontakt@pampers-pants-gratis-testen.de</a>`,
                                    classes: 'subHeading text-left'
                                  }
                                },
                              ]
                            }
                          },
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 5,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              "fields": [
                                {
                                  "fieldType": "square-image",
                                  "fieldId": "image",
                                  "properties": {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "50px"
                                    },
                                    "imageSource": "assets/images/png/expired.png",
                                    "alternateText": "SMS",
                                    "classes": "sms-image",
                                    "styles": {
                                      "max-width": "400px"
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Zurück zur Startseite",
                        "grid": {
                          "columns": 6,
                          "alignItems": "center",
                          "justifyContent": "center",
                          marginBottom: "50px"
                        },
                        "classes": "btn-primary",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "/"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Receipt + Product Upload
        "pageName": "receipt-product-reupload",
        pageId: 10098,
        isPublic: true,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Query String to State
                      "fieldType": "query-string-to-state",
                      "fieldId": "uwxo2o1",
                      properties: {
                        grid: {
                          columns: 12
                        },
                        variables: [
                          {
                            query: "referenceNumber",
                            state: "referenceNumber"
                          },
                          {
                            query: "token",
                            state: "receiptToken"
                          },
                        ]
                      }
                    },
                    { // 72 Hours check
                      "fieldType": "checkReceiptToken",
                      "fieldID": "dksnfsdkjf",
                      properties: {
                        grid: { columns: 12 }
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Kaufbeleg + Produktrückseite mit Barcode hochladen",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: `Bitte laden Sie in diesem Schritt den Kaufbeleg Ihres Produktes, gemeinsam mit der Rückseite Ihres Produktes hoch. Bitte beachten Sie, dass der Barcode und das Aktionsprodukt gut ersichtlich sein müssen.`,
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Receipt uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "alignItems": "center",
                          "justifyContent": "space-around",
                          "marginBottom": "60px"
                        },
                        "classes": "receipt-bucket",
                        "fields": [
                          { // Recipt upload
                            "fieldType": "receipt-processor",
                            "fieldId": "klvakj",
                            "properties": {
                              "grid": {
                                "columns": 5,
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              "successAction": {
                                "fn": "goToPage",
                                "params": "thank-you"
                              },
                              texts: {
                                choose: 'Upload',
                                text1: 'Drop your photo here or select file.',
                                text2: 'Only JPG, PNG and PDF files are supported Maximum file size: 10MB',
                                wrongFormat: 'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.',
                                fileSizeLimit: 'The file is too large. Only files up to 10MB are supported.'
                              },
                              editTexts: {
                                crop: "Crop",
                                erase: "Erase",
                                drag: "Drag",
                                zoomIn: "Zoom in",
                                zoomOut: "Zoom out"
                              },
                              reUpload: true,
                              colorSchema: "#DF1B25",
                              "phrases": [
                                "Please erase your personal data with the eraser tool.",
                                "Please frame the receipt / invoice in the gray rectangle."
                              ],
                              "cornerDetectionPhrases": [
                                "Please erase your personal data with the eraser tool.",
                                "Please frame the receipt / invoice in the gray rectangle."
                              ]
                            }
                          },
                          { // Receipt Info
                            "fieldType": "empty-bucket",
                            "fieldId": "vdf932fd",
                            "properties": {
                              "grid": {
                                "columns": 7
                              },
                              "fields": [
                                {
                                  "fieldType": "receipt-information",
                                  "fieldId": "5mq1p6",
                                  "properties": {
                                    "grid": {
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "columns": 12
                                    },
                                    shouldDisplayFull: true,
                                    "texts": {
                                      "descText": `The following information must be visible:`,
                                      "descSubText": `Folded or scanned receipts or invoices will not be accepted`,
                                      "correct": "Valid",
                                      "notReadabe": "Hard to read",
                                      "folded": "Folded",
                                      "cropped": "Cut off"
                                    },
                                    "hideExamples": false,
                                    "imageSrc": "assets/images/png/receipt-product.png",
                                    "steps": [
                                      { // Retailer
                                        "text": "Retailer / Shop",
                                        "label": "A"
                                      },
                                      {
                                        "text": "Campaign product and its price",
                                        "label": "B"
                                      },
                                      {
                                        "text": "Total sum of the receipt / invoice and applied VAT",
                                        "label": "C"
                                      },
                                      {
                                        "text": "Date and time of purchase",
                                        "label": "D"
                                      },
                                      {
                                        "text": "Receipt / Invoice ID (not the bank transaction ID)",
                                        "label": "E"
                                      },
                                      {
                                        "text": "Barcode-Number",
                                        "label": "F"
                                      },
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Weiter",
                              classes: "btn-primary",
                              "requires": ["klvakj"],
                              "action": {
                                "fn": "sendReceipt",
                                "params": ""
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Product ReUpload
        "pageName": "product-reupload",
        isPublic: true,
        pageId: 1214,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Query String to State
                      "fieldType": "query-string-to-state",
                      "fieldId": "uwxo2o1",
                      properties: {
                        grid: {
                          columns: 12
                        },
                        variables: [
                          {
                            query: "referenceNumber",
                            state: "referenceNumber"
                          },
                          {
                            query: "token",
                            state: "receiptToken"
                          },
                        ]
                      }
                    },
                    { // 72 Hours check
                      "fieldType": "checkReceiptToken",
                      "fieldID": "dksnfsdkjf",
                      properties: {
                        grid: { columns: 12 }
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Produktfoto hochladen",
                        classes: 'heading text-left'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Bitte laden Sie ein Bild von der Rückseite Ihres Aktionsproduktes hoch.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Product Photo uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "columnGap": "20px",
                          "marginBottom": "50px"
                        },
                        "fields": [
                          { // Product photo upload
                            "fieldType": "product-upload",
                            "fieldId": "prodPhotoRe",
                            "properties": {
                              texts: {
                                choose: 'Upload',
                                text1: 'Drop your photo here or select file.',
                                text2: 'Only JPG and PNG files are supported Maximum file size: 10MB',
                                wrongFormat: 'This file format is not supported. Only .jpeg and .png files are accepted.',
                                fileSizeLimit: 'The file is too large. Only files up to 10MB are supported.'
                              },
                              "colorSchema": "var(--color2)",
                              "grid": {
                                "columns": "6",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              reUpload: true,
                              "endpoint": "/reuploadProductPhoto",
                              "successAction": {
                                "fn": "goToPage",
                                "params": "thank-you"
                              }
                            }
                          },
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "column"
                              },
                              "fields": [
                                {
                                  "fieldType": "product-information",
                                  "fieldId": "5mq1p6",
                                  "properties": {
                                    "grid": {
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "columns": "12"
                                    },
                                    "texts": {
                                      desc: "",
                                      subDesc: ""
                                    },
                                    "image": "assets/images/png/product.png"
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Weiter",
                              classes: "btn-primary",
                              "requires": ["prodPhotoRe"],
                              "action": {
                                "fn": "sendProductPhotos",
                                "params": "prodPhotoRe"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Receipt ReUpload
        "pageName": "receipt-reupload",
        isPublic: true,
        pageId: 1215,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Query String to State
                      "fieldType": "query-string-to-state",
                      "fieldId": "uwxo2o1",
                      properties: {
                        grid: {
                          columns: 12
                        },
                        variables: [
                          {
                            query: "referenceNumber",
                            state: "referenceNumber"
                          },
                          {
                            query: "token",
                            state: "receiptToken"
                          },
                        ]
                      }
                    },
                    { // 72 Hours check
                      "fieldType": "checkReceiptToken",
                      "fieldID": "dksnfsdkjf",
                      properties: {
                        grid: { columns: 12 }
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Kaufbeleg hochladen",
                        classes: 'heading text-left'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Bitte laden Sie in diesem Schritt den Kaufbeleg Ihres Produktes hoch.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Product Photo uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "columnGap": "20px",
                          "marginBottom": "50px"
                        },
                        "fields": [
                          { // Product photo upload
                            "fieldType": "product-upload",
                            "fieldId": "prodPhotoRe",
                            "properties": {
                              texts: {
                                choose: 'Upload',
                                text1: 'Drop your photo here or select file.',
                                text2: 'Only JPG and PNG files are supported Maximum file size: 10MB',
                                wrongFormat: 'This file format is not supported. Only .jpeg and .png files are accepted.',
                                fileSizeLimit: 'The file is too large. Only files up to 10MB are supported.'
                              },
                              "colorSchema": "var(--color2)",
                              "grid": {
                                "columns": "6",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              reUpload: true,
                              "endpoint": "/reuploadProductPhoto",
                              "successAction": {
                                "fn": "goToPage",
                                "params": "thank-you"
                              }
                            }
                          },
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "column"
                              },
                              "fields": [
                                {
                                  "fieldType": "receipt-information",
                                  "fieldId": "5mq1p6",
                                  "properties": {
                                    "grid": {
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "columns": 12
                                    },
                                    shouldDisplayFull: true,
                                    "texts": {
                                      "descText": `The following information must be visible:`,
                                      "descSubText": `Folded or scanned receipts or invoices will not be accepted`,
                                      "correct": "Valid",
                                      "notReadabe": "Hard to read",
                                      "folded": "Folded",
                                      "cropped": "Cut off"
                                    },
                                    "hideExamples": true,
                                    "imageSrc": "assets/images/png/receipt.png",
                                    "steps": [
                                      { // Retailer
                                        "text": "Retailer / Shop",
                                        "label": "A"
                                      },
                                      {
                                        "text": "Campaign product and its price",
                                        "label": "B"
                                      },
                                      {
                                        "text": "Total sum of the receipt / invoice and applied VAT",
                                        "label": "C"
                                      },
                                      {
                                        "text": "Date and time of purchase",
                                        "label": "D"
                                      },
                                      {
                                        "text": "Receipt / Invoice ID (not the bank transaction ID)",
                                        "label": "E"
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Weiter",
                              classes: "btn-primary",
                              "requires": ["prodPhotoRe"],
                              "action": {
                                "fn": "sendProductPhotos",
                                "params": "prodPhotoRe"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // DOI
        pageName: "doi",
        pageId: 125,
        isPublic: true,
        sections: [
          { // Steps and Header
            grid: {
              columns: 12,
              justifyContent: "center",
              alignItems: "center",
              margin: 0
            },
            sectionId: "fu30zq",
            classes: "container",
            fields: [
              { // DOI CHECK
                fieldType: "doi",
                fieldId: "doiasdasda",
                properties: {
                  endpoint: '/checkDoi'
                }
              },
              { // Container
                fieldType: "empty-bucket",
                fieldId: "fu0923r",
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "80px"
                  },
                  fields: [
                    {
                      fieldType: "empty-bucket",
                      fieldId: "fu0923r",
                      properties: {
                        grid: {
                          columns: 6,
                          justifyContent: "center",
                          alignItems: "center"
                        },
                        fields: [
                          { // Heading
                            fieldType: "paragraph",
                            fieldId: "par1001",
                            properties: {
                              grid: {
                                columns: 12,
                                marginBottom: "30px"
                              },
                              text: "Danke für Ihre Anmeldung",
                              classes: 'heading-black text-left'
                            }
                          },
                          { // Subheading
                            fieldType: "paragraph",
                            fieldId: "par1001",
                            properties: {
                              grid: {
                                columns: 12,
                                marginBottom: "50px"
                              },
                              text: "Sie haben sich erfolgreich für unseren Newsletter angemeldet. Ab sofort erfahren Sie als Eine/Einer der Ersten, sobald des kostenlose Produkttests, Gewinnspiele und inspirierende Artikel gibt.",
                              classes: 'subHeading text-left'
                            }
                          }
                        ]
                      }
                    },
                    { // Thank you image mobile
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 6,
                          "justifyContent": "center",
                          "alignItems": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "square-image",
                            "fieldId": "image",
                            "properties": {
                              grid: {
                                columns: 12,
                                marginBottom: "50px"
                              },
                              "imageSource": "assets/images/png/thanks.png",
                              "alternateText": "SMS",
                              "classes": "sms-image",
                              "styles": {
                                "max-width": "250px"
                              }
                            }
                          }
                        ]
                      }
                    },
                  ]
                }
              }
            ]
          },
          { // Section
            grid: {
              columns: 12,
              alignItems: "center",
              justifyContent: "center"
            },
            sectionId: "fu30zq",
            classes: "container",
            fields: [
              { // Container
                fieldType: "empty-bucket",
                fieldId: "fu0923r",
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "60px"
                  },
                  fields: [
                    {
                      fieldType: "empty-bucket",
                      fieldId: "fu0923r",
                      properties: {
                        grid: {
                          columns: 8,
                          justifyContent: "center",
                          alignItems: "center"
                        },
                        classes: "container",
                        fields: [

                        ]
                      }
                    },
                    { // Key visual
                      fieldType: "key-visual",
                      fieldId: "key100",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        styles: {
                          padding: '0 0 0 0',
                          maxWidth: '75vw'
                        },
                        noContainer: true,
                        desktopImageUrl: "assets/images/banner/banner-desktop.png",
                        tabletImageUrl: "assets/images/banner/banner-desktop.png",
                        mobileImageUrl: "assets/images/banner/banner-mobile.png"
                      }
                    },
                    { // Container
                      fieldType: "empty-bucket",
                      fieldId: "fu0923r",
                      properties: {
                        grid: {
                          columns: 12,
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "60px"
                        },
                        classes: "container",
                        fields: [
                          { // Buttons
                            fieldType: "empty-bucket",
                            fieldId: "fu0923r",
                            properties: {
                              grid: {
                                columns: 12,
                                marginBottom: "30px"
                              },
                              classes: "btn-bucket",
                              fields: [
                                { // Continue Button
                                  fieldType: "button-component",
                                  fieldId: "but100",
                                  properties: {
                                    grid: {
                                      columns: 8
                                    },
                                    text: "Zurück zur PAMPERS website",
                                    classes: "btn-primary",
                                    action: {
                                      fn: "goToLink",
                                      params: "https://www.pampers.de/"
                                    },
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // IBAN Reupload
        "pageName": "iban-reupload",
        pageId: 120312,
        isPublic: true,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Query String to State
                      "fieldType": "query-string-to-state",
                      "fieldId": "uwxo2o1",
                      properties: {
                        grid: {
                          columns: 12
                        },
                        variables: [
                          {
                            query: "referenceNumber",
                            state: "referenceNumber"
                          },
                          {
                            query: "customerToken",
                            state: "customerToken"
                          },
                          {
                            query: 'campaignId',
                            state: 'campaignId'
                          }
                        ]
                      }
                    },
                    { // 72 Hours check
                      "fieldType": "iban-reupload",
                      "fieldID": "dksnfsdkjf",
                      properties: {
                        grid: { columns: 12 }
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Bankverbindung angeben",
                        classes: 'heading text-left'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Bitte teilen Sie uns mit, auf welches Konto der Erstattungsbetrag überwiesen werden soll.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Register Form
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "columnGap": "20px",
                          "marginBottom": "50px"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              classes: "register-page-bucket",
                              "fields": [
                                {
                                  "fieldType": "form-component",
                                  "fieldId": "ibanReForm",
                                  "properties": {
                                    "grid": {
                                      "columns": 12,
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "marginBottom": "50px",
                                      "padding": "20px"
                                    },
                                    "endpoint": "/updateIban",
                                    "successAction": {
                                      "fn": "goToPage",
                                      "params": "iban-thank-you"
                                    },
                                    "fields": [
                                      {
                                        "fieldId": "fu2309b",
                                        "fieldType": "empty-bucket",
                                        "properties": {
                                          "grid": {
                                            "columns": 12,
                                            "marginBottom": "20px"
                                          },
                                          "classes": "form-wrapper",
                                          "fields": [
                                            {
                                              "fieldId": "b4baqs",
                                              "fieldType": "text-input",
                                              "properties": {
                                                "name": "firstname",
                                                "id": "firstname",
                                                "label": "Vorname Kontoinhaber",
                                                "placeholder": "Vorname Kontoinhaber",
                                                "text": "",
                                                labelOnTop: true,
                                                "validation": "required|ibanName",
                                                "grid": {
                                                  "columns": 12
                                                }
                                              }
                                            },
                                            {
                                              "fieldId": "gofije",
                                              "fieldType": "text-input",
                                              "properties": {
                                                "name": "lastname",
                                                "id": "lastname",
                                                "label": "Nachname Kontoinhaber",
                                                "placeholder": "Nachname Kontoinhaber",
                                                "text": "",
                                                labelOnTop: true,
                                                "validation": "required|ibanName",
                                                "grid": {
                                                  "columns": 12
                                                }
                                              }
                                            },
                                            {
                                              "fieldId": "b4baqq",
                                              "fieldType": "text-input",
                                              "properties": {
                                                "name": "iban",
                                                "id": "iban",
                                                "label": "IBAN",
                                                "placeholder": "IBAN",
                                                "text": "",
                                                labelOnTop: true,
                                                "validation": "required|iban",
                                                "grid": {
                                                  "columns": 12
                                                }
                                              }
                                            },
                                            {
                                              "fieldId": "gofijx",
                                              "fieldType": "text-input",
                                              "properties": {
                                                "name": "bic",
                                                "id": "bic",
                                                "label": "BIC",
                                                "placeholder": "BIC",
                                                "text": "",
                                                labelOnTop: true,
                                                "validation": "required|bic",
                                                "grid": {
                                                  "columns": 12
                                                }
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        "fieldType": "paragraph",
                                        "fieldId": "par1001",
                                        "properties": {
                                          "grid": {
                                            "columns": 12,
                                            "marginBottom": "20px"
                                          },
                                          "text": '* Vermeiden Sie eine Änderung bei den Umlauten: <b>Müller bzw. Mueller.</b> Vermeiden Sie ein Hinzufügen oder Weglassen von Bindestrichen: <b>Marie Luise bzw. Marie-Luise.</b> Geben Sie den zweiten Vornamen nur dann an, wenn auch das Konto so geführt wird. Sollten die Daten nicht mit den Kontodaten übereinstimmen, kommt es in den meisten Fällen zu Rückbuchungen seitens der Bank und erschwert den Ablauf einer unkomplizierten und raschen Rückerstattung.',
                                          classes: 'subHeading text-left',
                                          styles: {
                                            "font-size": "14px",
                                            "font-weight": "400"
                                          }
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Weiter",
                              classes: "btn-primary",
                              "action": {
                                "fn": "sendForm",
                                "params": "ibanReForm"
                              }
                            }
                          }
                        ]
                      }
                    },
                  ]
                }
              }
            ]
          }
        ]
      },
      { // IBAN Thank You
        "pageName": "iban-thank-you",
        pageId: 1209,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    {
                      "fieldType": "square-image",
                      "fieldId": "image",
                      "properties": {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        "imageSource": "assets/images/png/products.png",
                        "alternateText": "SMS",
                        "classes": "sms-image",
                        "styles": {
                          "max-width": "400px"
                        }
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 8,
                          marginBottom: "50px"
                        },
                        text: "Danke für die Teilnahme",
                        classes: 'heading text-center'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 8,
                          marginBottom: "50px"
                        },
                        text: `Vielen Dank, dass Sie Ihre Bankdaten erneut eingereicht haben. Einer erfolgreichen Überweisung sollte also nichts mehr im Wege stehen. Sollten Sie Fragen haben, die mit Ihren Bankdaten zusammenhängen, wenden Sie sich bitte an <a href='mailto:iban@pampers-pants-gratis-testen.de'>iban@pampers-pants-gratis-testen.de</a> Für sonstige Angelegen können Sie unseren Support Service unter <a href='mailto:kontakt@pampers-pants-gratis-testen.de'>kontakt@pampers-pants-gratis-testen.de</a> kontaktieren.`,
                        classes: 'subHeading text-center'
                      }
                    },
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Zurück zur Startseite",
                        "grid": {
                          "columns": 6,
                          "alignItems": "center",
                          "justifyContent": "center",
                          marginBottom: "50px"
                        },
                        "classes": "btn-primary",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "/"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
    ],
    "header": {
      "fieldType": "navbar",
      "fieldId": "cbx34t",
      "properties": {
        "grid": {
          "columns": 12
        },
        "logo": {
          imageSource: "assets/images/svg/logo.svg",
          url: '/',
          alt: 'Everyday Logo'
        },
        "favicon": {
          "imageSource": "assets/images/svg/pglogo.svg"
        },
        "fields": [
          {
            "fieldId": "ikz8vc",
            "fieldType": "anchor",
            "properties": {
              "text": "Teilnahmebedingungen",
              "url": "/teilnahmebedingungen",
              "target": "_blank",
              "cssClass": "nav-link headingLink",
              "styles": {
                'text-decoration': 'none !important',
                'font-weight': 'bold !important'
              }
            }
          },
          {
            "fieldId": "slnmdk",
            "fieldType": "anchor",
            "properties": {
              "text": "FAQ",
              "url": "/faq",
              "target": "_blank",
              "cssClass": "nav-link headingLink",
              "styles": {
                'text-decoration': 'none !important',
                'font-weight': 'bold !important'
              }
            }
          }
        ],
        "meta": [
          { "name": "title", "content": "Pampers Night Pants Gratis Testen Aktion" },
          {
            "name": "description",
            "content": ``
          },
          {
            "name": "keywords",
            "content": `Pampers, Gratis, Testen, Pampers Night Pants Gratis Testen Aktion, Pampers Night Pants Gratis Testen Aktion`
          }
        ]
      }
    },
    "footer": {
      "fieldType": "footer-component",
      "fieldId": "4tfbzz",
    },
    errors: {
      de: {
        default: "Hoppla, da ist was schief gelaufen!",
        noEmail: "Es ist kein Konto mit dieser E-Mail-Adresse vorhanden.",
        emailUsed: "Diese E-Mail-Adresse wird bereits verwendet",
        incorrect: "Unzulässiger Benutzername oder falsches Passwort. Bitte versuchen Sie es erneut!",
        invalidCode: "Der Link, den Sie verwenden möchten, ist bereits abgelaufen. Bitte fordern Sie einen neuen Link zum Zurücksetzen des Passworts an.",
        manyTries: 'Ein Fehler ist aufgetreten, weil der Beleg zu oft hochgeladen wurde.',
        generateCode: 'Der Bestätigungscode konnte nicht gesendet wenden, weil es sich um eine nicht zugelassene Telefonnummer handelt. Bitte überprüfen Sie die eingegebene Nummer und versuchen Sie es erneut.',
        wrongCode: 'Der SMS-Code ist falsch',
        network: 'Hoppla. Auf dem Server ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
        '15minsOver': 'Die Sitzung ist abgelaufen. Bitte laden Sie die Seite neu und versuchen Sie es erneut.',
        notActive: 'Ungültiger Teilnahmezeitraum. Bitte lesen Sie unsere Teilnahmebedingungen.',
        badRequest: 'Ungültige Anfrage: campaignKey ist erforderlich.',
        alreadyActive: 'Pro IP-Adresse ist nur eine Teilnahme innerhalb von 15 Minuten erlaubt. Bitte probieren Sie es in ein paar Minuten erneut.',
        dailyParticipationLimit: 'Das tägliche Limit wurde leider schon erreicht, aber es ist möglich, dass Plätze wieder frei werden, falls einige Kaufbelege abgelehnt werden. Bleiben Sie dran - vielleicht klappt es ja doch noch! Ansonsten können Sie es gerne morgen erneut versuchen. Der Zähler wird um 8 Uhr zurückgesetzt.'
      }
    },
    "url": "http://justsnap.everydayme.pl/",
    "name": "test-campaign"
  },
  componentStatus: {},
  modal: {
    type: 'error',
    message: 'Ein Fehler ist aufgetreten',
    isActive: false
  },
  campaignId: "pampers-night-24",
  campaignKey: "639f7ffb-1988-462c-8255-7aa498fde833",
  customerToken: '',
  receiptToken: '',
  receiptImage: '',
  referenceNumber: '',
  formData: new FormData(),
  campaignEndDate: '2024-05-30',
  scannerActive: false,
  file: {},
  barcode: {
    code: '',
    format: ''
  },
  productCode: '',
  searchStatus: null,
  imgList: [],
  errorMessage: "",
  errorActivate: false,
  scannedProducts: [],
  frontAndBack: false,
  countryCode: '',
  tel: '',
  consumerId: '',
  remainingCount: 0,
  jwtToken: '',
}
